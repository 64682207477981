import React from "react";
import { getLink } from "../routes";
import Hero from "./Hero";
import HorizontalCardCollection from "./Marketing/HorizontalCardCollection";

const Home = props => {
  const {
    image,
    network,
    _updatedAt,
    specificationDocumentWalkthroughs,
    roles,
    systemComponents,
    intro
  } = props;

  return (
    <>
      <Hero
        image={intro.summaryImage}
        superheading="Spotlight"
        heading={network.title}
        subheading={`${intro.summaryLine}`}
        subheadingSize="lead text-white"
        height="70vh"
        className="mb-3"
      />
      <HorizontalCardCollection
        collectionTitleRow={{ title: "Roles" }}
        items={roles}
        cardImageKey="avatar"
        cardHeadingKey="title"
        linkFormatter={item => getLink(network.slug.current, "role", item.title)}
      />
      <HorizontalCardCollection
        layout="grid"
        collectionTitleRow={{ title: "Components" }}
        items={systemComponents}
        cardImageKey="image"
        cardHeadingKey="title"
        linkFormatter={item => getLink(network.slug.current, "systemComponent", item.title)}
      />
      <HorizontalCardCollection
        layout="grid"
        collectionTitleRow={{ title: "Walkthroughs" }}
        items={specificationDocumentWalkthroughs}
        cardImageKey="image"
        cardHeadingKey="title"
        linkFormatter={item =>
          getLink(network.slug.current, "specificationDocumentWalkthrough", item.title)
        }
      />
    </>
  );
};

export default Home;
