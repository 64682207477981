import React, { Component } from "react";
import Home from "./Home";
import { getBaseSpotlightRoute } from "./SpotlightLayout2";

class Spotlight extends Component {
  render() {
    const {
      network,
      specificationDocumentWalkthroughs,
      path,
      systemComponents,
      roles,
      intro
    } = this.props;

    const networkSlugCurrent = network.slug.current;

    console.dir(this.props);

    return (
      (path === `/network/${networkSlugCurrent}` || path === getBaseSpotlightRoute(network)) && (
        <>
          <Home
            network={network}
            specificationDocumentWalkthroughs={specificationDocumentWalkthroughs}
            roles={roles}
            intro={intro}
            systemComponents={systemComponents}
          />
        </>
      )
    );
  }
}

export default Spotlight;

/* {intro && path === `/network/${networkSlugCurrent}/intro` && <Guide {...this.props} />}
{path === `/network/${networkSlugCurrent}/deep-dive` && <DeepDive {...this.props} />}
{path === `/network/${networkSlugCurrent}/details` && <Details {...this.props} />}
{path === `/network/${networkSlugCurrent}/quiz` && (
  <Jumbotron className="text-center">Quiz not available yet</Jumbotron>
)}
{path === `/network/${networkSlugCurrent}/whitepaper-walkthrough` && (
  <WhitepaperWalkthrough network={network} whitepaper={whitepaper} />
)}
{path === `/network/${networkSlugCurrent}/whitepaper-notes` && (
  <WhitepaperNotes whitepaper={whitepaper} />
)}
{path === `/network/${networkSlugCurrent}/economics-paper-walkthrough` && (
  <EconomicsPaperWalkthrough network={network} economicsPaper={economicsPaper} />
)}
{path === `/network/${networkSlugCurrent}/economics-paper-notes` && (
  <EconomicsPaperNotes economicsPaper={economicsPaper} />
)}*/
