import { Link } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import PortableText from "../portableText";

// interface ITitleRow {
//   title?: string
//   seeAllLink?: string
// }

// interface ITextDetail {
//   title?: string
//   description?: string
// }

// export interface HorizontalCardCollectionProps {
//   items?: any[]
//   collectionTitleRow?: ITitleRow
//   textDetail?: ITextDetail
//   marginBottom?: number
//   linkFormatter?: any
// }

// const getItemImage = (item: any) => {
//   return item.image && item.image.asset
//     ? item.image
//     : item.images
//     ? item.images[0]
//     : null
// }

const HorizontalCardCollection = ({
  layout = "horizontalList",
  items = [],
  collectionTitleRow = null,
  marginBottom = 64,
  linkFormatter = () => null,
  cardHeadingKey = "",
  cardSubheadingKey = "",
  cardImageKey = ""
}) => {
  switch (layout) {
    case "horizontalList":
      return (
        <div style={{ marginBottom, overflowX: "auto" }}>
          {collectionTitleRow && (
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="h3 mb-0">{collectionTitleRow.title}</div>
              {collectionTitleRow.seeAllLink && (
                <div>
                  <Link to={collectionTitleRow.seeAllLink}>See all →</Link>
                </div>
              )}
            </div>
          )}
          <div
            style={{
              overflow: "auto",
              minWidth: "100%",
              display: "flex",
              WebkitOverflowScrolling: "touch"
            }}
          >
            {items.map((item, i) => (
              <Link
                key={item._id}
                to={linkFormatter(item) || null}
                className="text-decoration-none"
              >
                <div
                  style={{
                    borderRadius: 5,
                    width: 250,
                    marginLeft: i !== 0 && 30,
                    paddingRight: 30,
                    marginTop: 10
                  }}
                  className="mb-3 border-right"
                >
                  {item[cardImageKey] && (
                    <Img
                      fluid={item[cardImageKey].asset.fluid}
                      className="img-fluid"
                      style={{ filter: !item.isReady && "grayscale(1)" }}
                    />
                  )}
                  <div
                    style={{ minHeight: 300 }}
                    className="pt-3 d-flex flex-column justify-content-between"
                  >
                    <div>
                      <div className="lead" style={{ minHeight: "3em" }}>
                        {item[cardHeadingKey] && item[cardHeadingKey]}
                      </div>
                      {item[cardSubheadingKey] && (
                        <PortableText blocks={item[cardSubheadingKey][0]} />
                      )}
                    </div>
                    {item.isReady ? (
                      <Link className="text-decoration-none" to="/">
                        <small>
                          Learn more
                          <span style={{ fontSize: 12 }} className="text-muted ml-2">
                            →
                          </span>
                        </small>
                      </Link>
                    ) : (
                      <small className="text-muted">Not available yet</small>
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      );
    case "grid":
      return (
        <div style={{ marginBottom, overflowX: "auto" }}>
          {collectionTitleRow && (
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="h3 mb-0">{collectionTitleRow.title}</div>
              {collectionTitleRow.seeAllLink && (
                <div>
                  <Link to={collectionTitleRow.seeAllLink}>See all →</Link>
                </div>
              )}
            </div>
          )}
          <Row>
            {items.map((item, i) => (
              <Col sm={4} key={item._id} className="mb-3">
                <Link to={linkFormatter(item) || null} className="text-decoration-none">
                  <Card style={{ overflow: "hidden" }}>
                    {item[cardImageKey] && (
                      <Img
                        fluid={item[cardImageKey].asset.fluid}
                        className="img-fluid"
                        style={{ filter: !item.isReady && "grayscale(1)" }}
                      />
                    )}
                    <Card.Body>
                      <div className="lead" style={{ minHeight: "3em" }}>
                        {item[cardHeadingKey] && item[cardHeadingKey]}
                      </div>
                      {cardSubheadingKey && item[cardSubheadingKey].length > 0 && (
                        <PortableText blocks={item[cardSubheadingKey][0]} />
                      )}
                      {item.isReady ? (
                        <Link className="text-decoration-none" to="/">
                          <small>
                            Learn more
                            <span style={{ fontSize: 12 }} className="text-muted ml-2">
                              →
                            </span>
                          </small>
                        </Link>
                      ) : (
                        <small className="text-muted">Not available yet</small>
                      )}
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      );
    default:
      break;
  }
};

export default HorizontalCardCollection;
